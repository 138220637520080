import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() {}

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Une erreur inconnue est survenue !';
    let errorStatus = error.status.toString();

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Erreur côté client : ${error.error.message}`;
    } else {
      errorMessage = error.error?.message || this.getServerErrorMessage(error);
    }

    return throwError(() => ({
      error: {
        status: errorStatus,
        message: errorMessage
      }
    }));
  }
  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404:
        return `Ressource non trouvée (404)`;
      case 500:
        return `Erreur interne du serveur (500)`;
      case 400:
        return `Requête incorrecte (400)`;
      case 401:
        return `Non autorisé (401)`;
      case 403:
        return `Accès refusé (403)`;
      default:
        return `Erreur inattendue (Code: ${error.status})`;
    }
  }
}
