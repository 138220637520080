import { Injectable } from '@angular/core';
import {ActivatedRoute, CanActivate, Router} from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private route: ActivatedRoute) {}

  canActivate(): Observable<boolean> {
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
      return this.authService.loadUser().pipe(
        map((response: { data: any }) => {
          const user = response.data;
          return user.plateform === 'BACKOFFICE_ADMIN' || user.plateform === 'BACKOFFICE_COMPANY';
        }),
        catchError(() => {
          this.router.navigate(['/login']);
          return of(false);
        })
      );
    } else {
      this.router.navigate(['/login']);
      return of(false);
    }
  }
}
